import { useState } from 'react'

import { Screen } from '../dataTypes'
import { MediaInfo } from './'
import { getScreenText } from '../lib'
import { ShowIcon, ViewOffIcon } from '../icons'

type ExpandButtonProperties = {
  isExpanded: boolean
  setExpanded: any
}
const ExpandButton = ({ isExpanded, setExpanded }: ExpandButtonProperties) => {
  return (
    <>
      <button
        onClick={(e) => {
          setExpanded(false)
          e.preventDefault()
        }}
        className={'btn btn-icon leading-normal float-left' + (isExpanded ? ' inline-block' : ' hidden')}
      >
        Hide media
        <div className="ml-sm float-right">
          <ViewOffIcon />
        </div>
      </button>
      <button
        onClick={(e) => {
          setExpanded(true)
          e.preventDefault()
        }}
        className={'btn btn-icon leading-normal' + (isExpanded ? ' hidden' : ' inline-block')}
      >
        Show media
        <div className="ml-sm float-right">
          <ShowIcon />
        </div>
      </button>
    </>
  )
}

type Props = {
  templateId: string
  screen: Screen
  originatingEnvironment: string
}
export const ScreenInfo = ({ templateId, screen, originatingEnvironment }: Props) => {
  const [isExpanded, setExpanded] = useState(false)

  const heading = getScreenText(screen.screenType).name

  return (
    <>
      <div className="flex justify-between">
        <div>
          <h2 className="text-lg mr-sm my-auto inline">{heading}</h2>
          <span>({screen.machineTypes.join(', ')})</span>
        </div>
        <ExpandButton isExpanded={isExpanded} setExpanded={setExpanded} />
      </div>
      <div className="flex flex-row flex-wrap">
        {screen.media.map((media) => (
          <MediaInfo
            key={'' + templateId + screen.screenType + media.mediaType}
            editUrlRoot={`/${templateId}/screen/${screen.screenType}/media/`}
            originatingEnvironment={originatingEnvironment}
            media={media}
            isExpanded={isExpanded}
          />
        ))}
      </div>
    </>
  )
}
