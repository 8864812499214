import { isImageFile, isVideoFile } from '../lib'

type Props = {
  src: string
  contentType: string
}

type ImagePreviewProps = {
  src: string
}

type VideoPreviewProps = {
  src: string
  contentType: string
}

const ImagePreview = ({ src }: ImagePreviewProps) => {
  return <img src={src} alt="preview" />
}

const VideoPreview = ({ src, contentType }: VideoPreviewProps) => {
  return (
    <video controls={true} autoPlay={true} muted={true} loop={true}>
      <source src={src} type={contentType} />
      Your browser does not support video
    </video>
  )
}

export const MediaFileView = ({ src, contentType }: Props) => {
  const contentTypeWithFallback = contentType ?? 'image/png'
  return (
    <div className="my-sm centerFlex">
      {isImageFile(contentTypeWithFallback) && <ImagePreview src={src} />}
      {isVideoFile(contentTypeWithFallback) && <VideoPreview src={src} contentType={contentType} />}
    </div>
  )
}
