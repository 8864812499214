import { MediaConfig } from '../dataTypes'
import { getMediaText } from '../lib'
import { MediaPreview } from './MediaPreview'
import { Badge } from './MediaInfoBadge'
import { Link } from 'wouter'

type MediaInfoProps = {
  editUrlRoot: string
  originatingEnvironment: string
  media: MediaConfig
  isExpanded: boolean
}

export const MediaInfo = ({ editUrlRoot, originatingEnvironment, media, isExpanded }: MediaInfoProps) => {
  const editUrl = `${editUrlRoot}${media.mediaType}?origEnv=${originatingEnvironment}`
  const title = getMediaText(media.mediaType).name
  const isCustom = !!media.customMedia
  const roundedClass = isExpanded ? 'rounded-half' : 'rounded'
  return (
    <Link href={editUrl} className={'block m-sm max-w-25 text-md py-sm px-md border px-lg ' + roundedClass}>
      <div>
        {title}
        <Badge isCustom={isCustom} />
      </div>
      <div className={isExpanded ? '' : 'hidden'}>
        <MediaPreview media={media} />
      </div>
    </Link>
  )
}
