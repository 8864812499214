type ViewOffIconProps = {
  color?: string
  size?: string
}

export const ViewOffIcon = (props: ViewOffIconProps) => (
  <svg
    fill={props.color || 'none'}
    viewBox="0 0 24 24"
    strokeWidth="1.5px"
    height={props.size || '24'}
    width={props.size || '24'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <line
        x1="2.78"
        y1="21"
        x2="21.53"
        y2="3"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></line>
      <path
        d="M9,19.05a9.91,9.91,0,0,0,3,.45c4.1.07,8.26-2.81,10.82-5.64a1.65,1.65,0,0,0,0-2.22,20.06,20.06,0,0,0-3.07-2.76"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M17.09,7.27A11.31,11.31,0,0,0,12,6C8,5.93,3.8,8.75,1.18,11.64a1.65,1.65,0,0,0,0,2.22,20,20,0,0,0,4.93,4"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M9,15.07a3.85,3.85,0,0,1,5.5-5.28"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M15.75,12.75h0A3.75,3.75,0,0,1,12,16.5"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </g>
  </svg>
)
