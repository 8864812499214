import { useEffect, useState } from 'react'
import { failure, loading, notAsked, RD, SRD, success } from 'srd'
import { TemplateRef } from '../dataTypes'
import { fetchTemplates, getLicenseAccountParam, getOriginatingEnvironmentParam } from '../lib'

type Props = {
  chosenExistingId: string | undefined
  setChosenExistingId: (id: string) => void
}

export const CopyTemplateChoice = ({ chosenExistingId, setChosenExistingId }: Props) => {
  const [remoteExistingTemplates, setRemoteExistingTemplates] = useState(notAsked() as RD<string, TemplateRef[]>)
  useState()
  const [enableExistingTemplatesDropdown, setEnableExistingTemplatesDropdown] = useState(false)
  const originatingEnvironmentParam = getOriginatingEnvironmentParam()

  useEffect(() => {
    let isCancelled = false
    setRemoteExistingTemplates(loading())
    fetchTemplates(originatingEnvironmentParam, getLicenseAccountParam())
      .then((data) => {
        if (!isCancelled) {
          setRemoteExistingTemplates(success(data))
        }
      })
      .catch((e) => {
        if (!isCancelled) {
          setRemoteExistingTemplates(failure(e.message))
        }
        console.log('Error setting existing templates', e)
      })
    return () => {
      isCancelled = true
    }
  }, [originatingEnvironmentParam])

  return (
    <div className="mb-md">
      {SRD.match(
        {
          failure(e: string) {
            return <div>{e}</div>
          },
          loading() {
            return <div>loading existing templates...</div>
          },
          notAsked() {
            return <div>loading existing templates...</div>
          },
          success(existingTemplates: TemplateRef[]) {
            return (
              <div>
                {existingTemplates && (
                  <div className="mb-md">
                    <input
                      type="checkbox"
                      id="enable-copy"
                      className="mr-md"
                      value="yes"
                      checked={enableExistingTemplatesDropdown}
                      disabled={!existingTemplates.length}
                      onChange={() => {
                        setEnableExistingTemplatesDropdown(!enableExistingTemplatesDropdown)
                        enableExistingTemplatesDropdown
                          ? setChosenExistingId('')
                          : setChosenExistingId(existingTemplates[0].id)
                      }}
                    />
                    <label className="label inline-block" htmlFor="enable-copy">
                      Copy from existing template {!existingTemplates.length && <span>(none available)</span>}
                    </label>
                    {enableExistingTemplatesDropdown && (
                      <div>
                        <label className="label inline-block mr-md" htmlFor="copy-templates">
                          Choose template:
                        </label>
                        <select
                          id="copy-templates"
                          className="select py-sm"
                          value={chosenExistingId}
                          onChange={(e) => setChosenExistingId(e.target.value)}
                        >
                          {existingTemplates.map((templateRef) => (
                            <option value={templateRef.id} key={templateRef.id}>
                              {templateRef.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )
          },
        },
        remoteExistingTemplates
      )}
    </div>
  )
}
