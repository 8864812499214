import { failure, loading, notAsked, RD, SRD, success } from 'srd'
import { addMedia, deleteMedia, getMediaText, ORIG_ENV_QUERY_NAME, updateMedia } from '../lib'
import { FileDrop } from './FileDrop'
import { Dispatch, FormEvent, SetStateAction, useState } from 'react'
import { FullTemplate, MediaConfig, Screen } from '../dataTypes'
import { Link } from 'wouter'
import { InterruptableLink } from './InterruptableLink'

type Props = {
  template: FullTemplate
  screen: Screen
  mediaConfig: MediaConfig
  onFileUploaded: (template: FullTemplate) => void
  onFileChosen: () => void
  newFile: File | null
  setNewFile: Dispatch<SetStateAction<File | null>>
}

type UploadResultState = [RD<string, string>, Dispatch<SetStateAction<RD<string, string>>>]

export const MediaUploadForm = ({
  template,
  screen,
  mediaConfig,
  onFileChosen,
  onFileUploaded,
  newFile,
  setNewFile,
}: Props) => {
  const [uploadResult, setUploadResult]: UploadResultState = useState(notAsked())

  const uploadFile = (e: FormEvent<HTMLFormElement>) => {
    setUploadResult(loading())
    if (newFile) {
      const formData = new FormData(e.currentTarget)
      formData.append('file', newFile, newFile.name)

      if (mediaConfig.customMedia?.filenamePath) {
        updateMedia(template.id, mediaConfig.customMedia.id, formData, template.originatingEnvironment)
          .then((data) => {
            setUploadResult(success('Saved'))
            onFileUploaded(data)
            console.log('Uploaded', data)
            setNewFile(null)
          })
          .catch((e) => {
            setNewFile(null)
            if (e.status === 400) {
              setUploadResult(failure(`Error: ${e.body.detail ?? 'unknown error'}`))
            } else {
              setUploadResult(failure(e.message))
            }
            console.log('Upload error', e)
          })
      } else {
        addMedia(template.id, screen.screenType, mediaConfig.mediaType, formData, template.originatingEnvironment)
          .then((data) => {
            setUploadResult(success('Saved'))
            onFileUploaded(data)
            console.log('Uploaded', data)
            setNewFile(null)
          })
          .catch((e) => {
            if (e.status === 400) {
              setUploadResult(failure(`Error: ${e.body.detail ?? 'unknown error'}`))
            } else {
              setUploadResult(failure(e.message))
            }
            setNewFile(null)
            console.log('Upload error', e)
          })
      }
      e.currentTarget.reset()
    }
    e.preventDefault()
  }

  return (
    <form onSubmit={(e) => uploadFile(e)}>
      <div className="centerFlex">{SRD.isLoading(uploadResult) && <div className="text-xl loadingSpinner" />}</div>
      <div>
        <input
          className="mb-md"
          type="file"
          key={getMediaText(mediaConfig.mediaType).name}
          onChange={(e) => {
            if (!e.target.files?.length) {
              console.log('Error - no files selected!')
              return false
            }
            if (e.target.files?.length > 1) {
              console.log('Error - several files selected!')
              return false
            }
            setUploadResult(notAsked)
            setNewFile(e.target.files[0])
            e.preventDefault()
            onFileChosen()
          }}
        />
        <FileDrop newFile={newFile} setNewFile={setNewFile} />
        {SRD.isFailure(uploadResult) && <div className="alert alert-danger mb-sm">{uploadResult.error}</div>}
        {SRD.isSuccess(uploadResult) && <div className="alert alert-success mb-sm">{uploadResult.data}</div>}
      </div>
      <div className="mt-xl flex">
        <Link to={`/${template.id}?${ORIG_ENV_QUERY_NAME}=${template.originatingEnvironment}`}>
          <InterruptableLink className="btn mr-md ml-xs inline-block" hasUnsavedChanges={newFile !== null}>
            Done
          </InterruptableLink>
        </Link>
        <input
          className="btn btn-primary-dark inline-block ml-md"
          type="submit"
          value="Save"
          disabled={!newFile || SRD.isLoading(uploadResult)}
        />
        {mediaConfig.customMedia && (
          <button
            className="btn btn-primary-danger inline-block ml-xl"
            onClick={(e) => {
              setUploadResult(loading())

              deleteMedia(template.id, mediaConfig.customMedia!.id, template.originatingEnvironment)
                .then((data) => {
                  setUploadResult(success('Saved'))
                  onFileUploaded(data)
                  console.log('Uploaded', data)
                  setNewFile(null)
                })
                .catch((e) => {
                  setNewFile(null)
                  if (e.status === 400) {
                    setUploadResult(failure(`Error: ${e.body.detail ?? 'unknown error'}`))
                  } else {
                    setUploadResult(failure(e.message))
                  }
                  console.log('Upload error', e)
                })
            }}
          >
            Reset to default
          </button>
        )}
      </div>
    </form>
  )
}
