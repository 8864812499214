type LogoProps = {
  size?: string
  color?: string
}

export const Logo = (props: LogoProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={props.size || '36'} viewBox="0 0 33 33" width={props.size || '36'}>
    <path
      d="M16,1.5a2.92,2.92,0,0,1,2.92,2.92,2.74,2.74,0,0,1-.17.93l-.54,1.59a.3.3,0,0,0,.29.39H29.08v7.14a4.91,4.91,0,0,0-.94-.09,5,5,0,0,0,0,10.08,4.91,4.91,0,0,0,.94-.09V31.5H21a5,5,0,0,0-4.95-6,5.63,5.63,0,0,0-1.15.13,5,5,0,0,0-3.8,5.86H3.92V7.33h9.59a.29.29,0,0,0,.28-.39l-.54-1.59a3,3,0,0,1-.17-.93A2.92,2.92,0,0,1,16,1.5M16,0a4.42,4.42,0,0,0-4.42,4.42,4.35,4.35,0,0,0,.25,1.41H2.72a.3.3,0,0,0-.3.3V32.7a.29.29,0,0,0,.3.3H13a.2.2,0,0,0,.17-.31,3.54,3.54,0,0,1,2-5.59A3.88,3.88,0,0,1,16,27a3.54,3.54,0,0,1,3.54,3.54,3.47,3.47,0,0,1-.71,2.12A.2.2,0,0,0,19,33h11.3a.29.29,0,0,0,.3-.3V22.4a.19.19,0,0,0-.2-.19.22.22,0,0,0-.13,0,3.54,3.54,0,1,1-3-6.27,3.83,3.83,0,0,1,.88-.1,3.51,3.51,0,0,1,2.13.72.19.19,0,0,0,.31-.17V6.13a.3.3,0,0,0-.3-.3H20.17a4.35,4.35,0,0,0,.25-1.41A4.42,4.42,0,0,0,16,0Z"
      fill={props.color || 'var(--colors-yellow)'}
    />
  </svg>
)

export default Logo
