import { ENV, ORIG_ENV_QUERY_NAME, STAGE } from '../lib'

type Props = {
  licenseAccountParam: string
  templateId?: string
}

export const OrigEnvChooser = ({ licenseAccountParam, templateId }: Props) => {
  const envPostfix = `${ENV}-${STAGE}` === 'eu-prod' ? '' : '-test'
  const laPostfix = licenseAccountParam ? '&licenseAccount=' + licenseAccountParam : ''
  const templateIdPrefix = templateId ? '/' + templateId : ''
  return (
    <div>
      Choose originating environment:
      <a
        className="link ml-sm"
        href={`${templateIdPrefix}/?${ORIG_ENV_QUERY_NAME}=www1${envPostfix}${laPostfix}`}
      >{`www1${envPostfix}`}</a>
      <a
        className="link ml-sm"
        href={`${templateIdPrefix}/?${ORIG_ENV_QUERY_NAME}=www2${envPostfix}${laPostfix}`}
      >{`www2${envPostfix}`}</a>
    </div>
  )
}
