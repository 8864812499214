import {
  authenticatedDeleteRaw,
  authenticatedGet,
  authenticatedPost,
  authenticatedPostRaw,
  authenticatedPut,
  authenticatedPutRaw,
} from './httpClient'

import { API_HOST } from './environment'
import { FullTemplate, LicenseAccountRef, TemplateDetails, TemplateRef } from '../dataTypes'

export function fetchTemplates(originatingEnvironment: string, licenseAccountId: string): Promise<TemplateRef[]> {
  return authenticatedGet(
    `${API_HOST}/templatereferences?licenseAccountReference=${licenseAccountId}&originatingEnvironment=${originatingEnvironment}`
  )
    .run()
    .then((resp: { data: TemplateRef[] }) => resp.data)
}

export function fetchTemplate(templateId: string, originatingEnvironment: string): Promise<FullTemplate> {
  return authenticatedGet(`${API_HOST}/templates/${templateId}?originatingEnvironment=${originatingEnvironment}`)
    .run()
    .then((resp: { data: FullTemplate }) => resp.data)
}

export function createTemplate(template: TemplateDetails): Promise<FullTemplate> {
  return authenticatedPost(`${API_HOST}/templates`, template)
    .run()
    .then((resp: { data: FullTemplate }) => resp.data)
}

export function copyTemplate(
  originalTemplateId: String,
  name: String,
  originatingEnvironment: string
): Promise<FullTemplate> {
  return authenticatedPost(
    `${API_HOST}/templates/${originalTemplateId}/copy?originatingEnvironment=${originatingEnvironment}`,
    { name: name }
  )
    .run()
    .then((resp: { data: FullTemplate }) => resp.data)
}

export function editTemplate(id: string, template: TemplateDetails): Promise<TemplateDetails> {
  return authenticatedPut(`${API_HOST}/templates/${id}`, template)
    .run()
    .then((resp: { data: TemplateDetails }) => resp.data)
}

export function addMedia(
  templateId: string,
  screenType: string,
  mediaType: string,
  formData: FormData,
  originatingEnvironment: string
): Promise<FullTemplate> {
  return authenticatedPostRaw(
    `${API_HOST}/templates/${templateId}/screentype/${screenType}/mediatype/${mediaType}?originatingEnvironment=${originatingEnvironment}`,
    formData
  )
    .run()
    .then((resp: { data: FullTemplate }) => resp.data)
}

export function updateMedia(
  templateId: string,
  mediaId: string,
  formData: FormData,
  originatingEnvironment: string
): Promise<FullTemplate> {
  return authenticatedPutRaw(
    `${API_HOST}/templates/${templateId}/media/${mediaId}?originatingEnvironment=${originatingEnvironment}`,
    formData
  )
    .run()
    .then((resp: { data: FullTemplate }) => resp.data)
}

export function deleteMedia(
  templateId: string,
  mediaId: string,
  originatingEnvironment: string
): Promise<FullTemplate> {
  return authenticatedDeleteRaw(
    `${API_HOST}/templates/${templateId}/media/${mediaId}?originatingEnvironment=${originatingEnvironment}`
  )
    .run()
    .then((resp: { data: FullTemplate }) => resp.data)
}

export function fetchLicenseAccounts(originatingEnvironment: string): Promise<LicenseAccountRef[]> {
  return authenticatedGet(`${API_HOST}/licenseaccounts?environment=${originatingEnvironment}`)
    .run()
    .then((resp: { data: LicenseAccountRef[] }) => resp.data)
}

export function fetchLicenseAccount(
  originatingEnvironment: string,
  licenseAccountId: number
): Promise<LicenseAccountRef> {
  return authenticatedGet(`${API_HOST}/licenseaccounts/${licenseAccountId}?environment=${originatingEnvironment}`)
    .run()
    .then((resp: { data: LicenseAccountRef }) => resp.data)
}
