import { Dispatch, SetStateAction, useState } from 'react'
import { RD, success } from 'srd'

import { FullTemplate, MediaType, ScreenType } from '../dataTypes'
import { findAssociatedMedia, getMediaText, getScreenText, ORIG_ENV_QUERY_NAME } from '../lib'
import { MediaUploadForm } from '../components/MediaUploadForm'
import { MediaUploadInfo } from '../components'
import { Preview } from '../components/Preview'
import { Link } from 'wouter'
import { Badge } from '../components/MediaInfoBadge'
import { InterruptableLink } from '../components/InterruptableLink'
import { ArrowRightIcon, HomeIcon, QuestionCircleIcon } from '../icons'

type Props = {
  screenType: ScreenType
  mediaType: MediaType
  template: FullTemplate
  setTemplate: Dispatch<SetStateAction<RD<string, FullTemplate>>>
}

type FileState = [File | null, Dispatch<SetStateAction<File | null>>]

function onBeforeUnload(e: BeforeUnloadEvent) {
  e.preventDefault()
  e.returnValue = '' // Chrome requires returnValue to be set
}

export const UploadMediaPage = ({ screenType, mediaType, template, setTemplate }: Props) => {
  const [unsavedChanges, setUnsavedChanges] = useState(false)
  const screen = template.screens?.find((s) => s.screenType === screenType)
  const isLockedForModification = template.finalizedAt || template.deactivatedAt
  const [newFile, setNewFile]: FileState = useState(null) as FileState

  if (!screen) {
    return (
      <div className="alert alert-danger">
        Template {template.id} does not support screen of type {`${screenType}`} in
      </div>
    )
  }
  let additionalMedia = findAssociatedMedia(screen, mediaType)

  const chosenMediaConfig = screen.media?.find((p) => p.mediaType === mediaType)
  if (!chosenMediaConfig) {
    return (
      <div className="alert alert-danger">
        Template {template.id} does not support media of type {`${screenType}/${mediaType}`}.
      </div>
    )
  }
  const screenDescription = getScreenText(screen.screenType).name
  const mediaTexts = getMediaText(chosenMediaConfig.mediaType)
  const templatesUrl = `https://${template.originatingEnvironment}.tomraconnect.com/page/promotion/media?licenseAccountId=${template.licenseAccountReference}`

  return (
    <div>
      <div className="card py-md px-lg flex items-center space-x-md">
        <a href={templatesUrl} aria-label="Home">
          <HomeIcon size="1.5rem" />
        </a>
        <ArrowRightIcon size="1.5rem" />
        <Link to={`/${template.id}?${ORIG_ENV_QUERY_NAME}=${template.originatingEnvironment}`}>
          <InterruptableLink className="link" hasUnsavedChanges={unsavedChanges}>
            {template.name}
          </InterruptableLink>
        </Link>
        <ArrowRightIcon size="1.5rem" />
        {screenDescription}
        <ArrowRightIcon size="1.5rem" />
        {getMediaText(chosenMediaConfig.mediaType).name}
        <ArrowRightIcon size="1.5rem" />
        Upload media
      </div>
      <div className="mb-lg">
        <h1 className="text-xl mr-sm my-auto mb-sm">{template.name}</h1>
        <h2 className="text-md font-bold mr-md my-auto inline">{screenDescription}</h2>
        <span>({screen.machineTypes.join(', ')})</span>
      </div>

      <div className="flex justify-start">
        <div className="tabs">
          {screen.media?.sort().map((mediaConfig) => {
            const mediaTypeName = getMediaText(mediaConfig.mediaType).name
            let isCustom = !!mediaConfig.customMedia
            return chosenMediaConfig.mediaType === mediaConfig.mediaType ? (
              <span className="tab active flex-initial px-xl flex items-center" key={mediaConfig.mediaType}>
                <span>{mediaTypeName}</span>
                <Badge isCustom={isCustom} />
              </span>
            ) : (
              <Link
                className="tab flex-initial px-xl"
                key={mediaConfig.mediaType}
                to={`${mediaConfig.mediaType}?${ORIG_ENV_QUERY_NAME}=${template.originatingEnvironment}`}
              >
                <InterruptableLink
                  className="tab flex-initial px-xl"
                  hasUnsavedChanges={unsavedChanges}
                  onNavigate={() => {
                    setNewFile(null)
                    setUnsavedChanges(false)
                  }}
                >
                  <span>{mediaTypeName}</span>
                  <Badge isCustom={isCustom} />
                </InterruptableLink>
              </Link>
            )
          })}
        </div>
      </div>
      <div className="card px-xl py-md my-lg">
        <div className="mb-md">
          <h2 className="text-lg pb-md inline mr-md">{mediaTexts.name}</h2>
          <abbr
            className="inline-flex"
            title={mediaTexts.extraInfo + ' The Tomra default media will be used if no media is uploaded.'}
          >
            <QuestionCircleIcon size="20" />
          </abbr>
        </div>
        <div className="flex">
          <div className="flex-initial w-32 pr-1">
            <MediaUploadInfo media={chosenMediaConfig} />
            {isLockedForModification ? (
              ''
            ) : (
              <div className="mt-xl">
                <MediaUploadForm
                  template={template}
                  screen={screen}
                  mediaConfig={chosenMediaConfig}
                  onFileChosen={() => {
                    setUnsavedChanges(true)
                    window.addEventListener('beforeunload', onBeforeUnload)
                  }}
                  onFileUploaded={(template) => {
                    setUnsavedChanges(false)
                    window.removeEventListener('beforeunload', onBeforeUnload)
                    setTemplate(success(template))
                  }}
                  newFile={newFile}
                  setNewFile={setNewFile}
                />
              </div>
            )}
          </div>

          <div className="flex-auto">
            <h2 className="text-lg text-center">Preview</h2>
            <Preview screenType={screenType} media={chosenMediaConfig} additionalMedia={additionalMedia} />
          </div>
        </div>
      </div>
    </div>
  )
}
