type EditIconProps = {
  color?: string
  size?: string
}

export const EditIcon = (props: EditIconProps) => (
  <svg
    fill={props.color || 'none'}
    height={props.size || '24'}
    viewBox="0 0 24 24"
    width={props.size || '24'}
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth="1.5px"
    stroke="#000000"
  >
    <path
      className="a"
      d="M22.19,1.81a3.638,3.638,0,0,0-5.169.035l-14.5,14.5L.75,23.25l6.905-1.771,14.5-14.5A3.638,3.638,0,0,0,22.19,1.81Z"
    />
    <line className="a" x1="16.606" y1="2.26" x2="21.74" y2="7.394" />
    <line className="a" x1="14.512" y1="4.354" x2="19.646" y2="9.488" />
    <line className="a" x1="2.521" y1="16.345" x2="7.66" y2="21.474" />
  </svg>
)
