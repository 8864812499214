import { useState } from 'react'
import { failure, loading, notAsked, RD, SRD, success } from 'srd'
import { Route, Switch, useRoute } from 'wouter'

import { Header, LicenseAccountChooser, OrigEnvChooser } from './components'
import { StartPage } from './pages/StartPage'
import { UploadMediaPage } from './pages/UploadMediaPage'
import { EditPage } from './pages/EditPage'
import { FullTemplate, MediaType, ScreenType } from './dataTypes'
import { fetchTemplate, getLicenseAccountParam, getOriginatingEnvironmentParam } from './lib'

type Props = {
  doLogout: () => void
  userToken: string
}

export const App = ({ doLogout, userToken }: Props) => {
  const originatingEnvironmentParam = getOriginatingEnvironmentParam()
  const licenseAccountParam = getLicenseAccountParam()
  const [hasTemplateIdParam, outerParams] = useRoute('/:templateId/:rest*')
  const [remoteTemplate, setRemoteTemplate] = useState(notAsked() as RD<string, FullTemplate>)

  const templateOriginatingEnvironment: RD<string, string> = SRD.map((t) => t.originatingEnvironment, remoteTemplate)
  const templateLicenseAccountId: RD<string, string> = SRD.map((t) => t.licenseAccountReference, remoteTemplate)

  const originatingEnvironment: string = SRD.withDefault(originatingEnvironmentParam, templateOriginatingEnvironment)
  const licenseAccountId: string = SRD.withDefault(licenseAccountParam, templateLicenseAccountId)

  return (
    <>
      <Header
        doLogout={doLogout}
        userToken={userToken}
        originatingEnvironment={originatingEnvironment}
        licenseAccountId={licenseAccountId}
      />
      <div className="container px-md my-md max-w-screen-xl">
        {SRD.match(
          {
            failure(e: string) {
              return <div className="alert alert-danger">Error: {e}</div>
            },
            loading() {
              return (
                <div className="centerFlex">
                  <div className="text-xl loadingSpinner" />
                </div>
              )
            },
            notAsked() {
              if (!originatingEnvironmentParam) {
                return <OrigEnvChooser licenseAccountParam={licenseAccountParam} templateId={outerParams?.templateId} />
              }
              if (hasTemplateIdParam && outerParams && outerParams.templateId) {
                setRemoteTemplate(loading())
                fetchTemplate(outerParams.templateId, originatingEnvironment)
                  .then((data) => {
                    setRemoteTemplate(success(data))
                  })
                  .catch((e) => setRemoteTemplate(failure(e.message)))
                return <h1>Loading template {outerParams.templateId}...</h1>
              }
              if (!licenseAccountParam) {
                return <LicenseAccountChooser originatingEnvironment={originatingEnvironmentParam} />
              }
              return (
                <StartPage
                  setTemplate={setRemoteTemplate}
                  licenseAccountId={licenseAccountId}
                  originatingEnvironment={originatingEnvironment}
                />
              )
            },
            success(template: FullTemplate) {
              if (!hasTemplateIdParam) {
                // Reset template state if user presses back in browser
                setRemoteTemplate(notAsked)
              }
              return (
                <Switch>
                  <Route path="/:templateId">
                    {() => <EditPage template={template} setTemplate={setRemoteTemplate} />}
                  </Route>
                  <Route path="/:templateId/screen/:screenType/media/:mediaType">
                    {(params) => (
                      <UploadMediaPage
                        screenType={params.screenType as ScreenType}
                        mediaType={params.mediaType as MediaType}
                        template={template}
                        setTemplate={setRemoteTemplate}
                      />
                    )}
                  </Route>
                  <Route>404 - no such page</Route>
                </Switch>
              )
            },
          },
          remoteTemplate
        )}
      </div>
    </>
  )
}

export default App
