import { MediaConfig, MediaType, Screen } from '../dataTypes'

export const findAssociatedMedia = (screen: Screen, mediaType: MediaType): MediaConfig[] => {
  if (screen.screenType === 'DOUBLE_TOUCH') {
    switch (mediaType) {
      case 'DOUBLE_TOP':
        return screen.media.filter((p) =>
          ['DOUBLE_SCREEN_BORDER', 'IDLE_DOUBLE_BOTTOM', 'WAIT_DOUBLE_BOTTOM'].includes(p.mediaType)
        )
      case 'DOUBLE_SCREEN_BORDER':
        return screen.media.filter((p) =>
          ['DOUBLE_TOP', 'IDLE_DOUBLE_BOTTOM', 'WAIT_DOUBLE_BOTTOM'].includes(p.mediaType)
        )
      case 'IDLE_DOUBLE_BOTTOM':
        return screen.media.filter((p) => ['DOUBLE_TOP', 'DOUBLE_SCREEN_BORDER'].includes(p.mediaType))
      case 'WAIT_DOUBLE_BOTTOM':
        return screen.media.filter((p) => ['DOUBLE_TOP', 'DOUBLE_SCREEN_BORDER'].includes(p.mediaType))
    }
  } else if (screen.screenType === 'UNO') {
    switch (mediaType) {
      case 'IDLE':
        return screen.media.filter((p) => p.mediaType === 'BORDER')
      case 'WAIT':
        return screen.media.filter((p) => p.mediaType === 'BORDER')
      case 'BORDER':
        return screen.media.filter((p) => ['IDLE', 'WAIT'].includes(p.mediaType))
    }
  }

  return []
}
