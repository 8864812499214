// @ts-nocheck
type EnvironmentConfig = {
  ENV: string
  STAGE: string
  VERSION: string
  CONFIG_BY_STAGE: { [key: string]: { CONFIG_BY_ENV: { [key: string]: any } } }
}

// @ts-ignore
const envConfig = window.__ENVIRONMENT__ as EnvironmentConfig

const { ENV, STAGE, VERSION, CONFIG_BY_STAGE } = envConfig
const { KEYCLOAK_HOST, API_HOST } = CONFIG_BY_STAGE[STAGE].CONFIG_BY_ENV[ENV]
const ORIG_ENV_QUERY_NAME = 'origEnv'
const ENV_NAME = `${ENV}-${STAGE}`

const fallbackOrigEnv: string =
  {
    lab: 'devtest',
    'us-test': 'www3-test',
    'us-prod': 'www3',
    'au-test': 'www4-test',
    'au-prod': 'www4',
  }[ENV_NAME] ?? ''

const urlParams = new URLSearchParams(window.location.search)

export function getOriginatingEnvironmentParam(): string {
  return urlParams.get(ORIG_ENV_QUERY_NAME) || fallbackOrigEnv
}

export function getLicenseAccountParam(): string {
  return urlParams.get('licenseAccount') || ''
}

export { ENV_NAME, ENV, API_HOST, KEYCLOAK_HOST, VERSION, STAGE, ORIG_ENV_QUERY_NAME }
