import { MediaConfig } from '../dataTypes'
import { MediaFileView } from './MediaFileView'

type Props = {
  media: MediaConfig
}

export const MediaPreview = ({ media }: Props) => {
  if (!media) {
    return <div className="alert alert-danger">No MediaConfig!</div>
  }
  const fileNamePath = media.customMedia?.thumbnailPath ?? media.defaultFilenamePath
  const contentType = media.customMedia?.contentType ?? media.defaultMediaContentType
  if (!fileNamePath) {
    return <div className="alert alert-danger">Missing default file for {media.mediaType}!</div>
  }

  return (
    <div className="my-sm centerFlex">
      <MediaFileView src={fileNamePath} contentType={contentType} />
    </div>
  )
}
