type InfoCircleIconProps = {
  color?: string
  size?: string
}

export const InfoCircleIcon = (props: InfoCircleIconProps) => (
  <svg
    fill={props.color || 'none'}
    height={props.size || '24'}
    viewBox="0 0 24 24"
    width={props.size || '24'}
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth="1.5px"
    stroke="#000000"
  >
    <path
      stroke="#000000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M14.25 16.5H13.5C13.1022 16.5 12.7206 16.342 12.4393 16.0607C12.158 15.7794 12 15.3978 12 15V11.25C12 11.0511 11.921 10.8603 11.7803 10.7197C11.6397 10.579 11.4489 10.5 11.25 10.5H10.5"
    ></path>
    <path
      stroke="#000000"
      d="M11.625 7.5C11.4179 7.5 11.25 7.33211 11.25 7.125C11.25 6.91789 11.4179 6.75 11.625 6.75"
    ></path>
    <path stroke="#000000" d="M11.625 7.5C11.8321 7.5 12 7.33211 12 7.125C12 6.91789 11.8321 6.75 11.625 6.75"></path>
    <path
      stroke="#000000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 23.25C18.2132 23.25 23.25 18.2132 23.25 12C23.25 5.7868 18.2132 0.75 12 0.75C5.7868 0.75 0.75 5.7868 0.75 12C0.75 18.2132 5.7868 23.25 12 23.25Z"
    ></path>
  </svg>
)
