import localforage from 'localforage' // ... or any other web storage library
import { AuthStore, WebStorage } from '@tomra/client-side-authentication'
import { KEYCLOAK_HOST, ORIG_ENV_QUERY_NAME } from './environment'
import { getLocaleForKeycloak } from './locale'

const storage: WebStorage = {
  setItem: localforage.setItem,
  removeItem: localforage.removeItem,
  clear: localforage.clear,
  getItem: localforage.getItem,
}

export const authentication = new AuthStore(
  'TomraConnectUsers',
  'promotion-ui',
  KEYCLOAK_HOST,
  getLocaleForKeycloak(),
  storage,
  getRedirectUrl()
)

function getRedirectUrl(): string {
  const url = window.location.href.split('?')[0]
  const q =
    window.location.search
      ?.split(/[?&]/)
      .filter((value) => value.startsWith(ORIG_ENV_QUERY_NAME))
      .reduce((previousValue, currentValue) => previousValue + currentValue + '&', '?') ?? ''
  return url + q
}
