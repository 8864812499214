import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { authentication, ENV_NAME } from './lib'
import { VERSION } from './lib'
import { logError } from './lib'
import { createRoot } from 'react-dom/client'
import '@tomra/design-system/src/config/tailwind.config.css'

const mountNode = document.getElementById('root')
if (!mountNode) {
  throw new Error('Could not get #root element')
}

const environment = (
  <div className="container mx-auto">
    {VERSION} | {ENV_NAME}
  </div>
)

authentication.init().then(
  () => {
    const container = document.getElementById('root')
    const root = createRoot(container!)
    root.render(
      <StrictMode>
        <App doLogout={authentication.logout} userToken={authentication.getToken()} />
        {environment}
      </StrictMode>
    )
  },
  (err: any) => {
    if (err === 'Unauthenticated') {
      authentication.login()
    } else {
      logError(new Error('Authentication failed'), err)

      ReactDOM.render(
        <div className="alert alert-danger">
          Wooopsie, authentication failed for some reason!
          {environment}
        </div>,
        mountNode
      )
    }
  }
)
