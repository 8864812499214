import { MediaConfig } from '../../dataTypes'

type Props = {
  media: MediaConfig
}

export const SmallColourRvmPreview = ({ media }: Props) => {
  const mediaUrl = media.customMedia?.filenamePath || media.defaultFilenamePath
  return (
    <div
      style={{
        backgroundImage: 'url(' + process.env.PUBLIC_URL + '/images/mk3-machine-bg.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: '402px',
        height: '231px',
        position: 'relative',
      }}
    >
      <div
        style={{
          position: 'absolute',
          backgroundColor: '#ebebeb',
          top: '60px',
          left: '253px',
          width: '90px',
          height: '66px',
        }}
      >
        <img
          src={mediaUrl}
          alt={'Small colour preview'}
          style={{
            position: 'absolute',
            top: '0',
            bottom: '0',
            left: '8px',
            maxWidth: '74px',
            maxHeight: '50px',
            width: '100%',
            margin: 'auto',
          }}
        />
      </div>
    </div>
  )
}
