import { MediaConfig } from '../dataTypes'
import { MediaPreview } from './MediaPreview'
import { DoubleTouchRvmPreview } from './rvm-previews/DoubleTouchRvmPreview'
import React, { useState } from 'react'

type Props = {
  media: MediaConfig
  additionalMedia: MediaConfig[]
}

type Tab = 'border' | 'idle' | 'wait'

function TabLink({
  name,
  title,
  activeTab,
  setActiveTabFn,
}: {
  name: Tab
  title: string
  activeTab: Tab
  setActiveTabFn: React.Dispatch<Tab>
}) {
  return activeTab === name ? (
    <span className="font-bold">{title}</span>
  ) : (
    <button
      className="link mx-sm"
      onClick={(e) => {
        e.preventDefault()
        setActiveTabFn(name)
      }}
    >
      {title}
    </button>
  )
}

export const DoubleBorderPreview = ({ media, additionalMedia }: Props) => {
  const [activeTab, setActiveTab] = useState('border' as Tab)
  const topMedia = additionalMedia.find((p) => p.mediaType === 'DOUBLE_TOP')
  if (!topMedia) {
    return <div className="alert alert-danger">Error: Top MediaConfig not found!</div>
  }
  const idleMedia = additionalMedia.find((p) => p.mediaType === 'IDLE_DOUBLE_BOTTOM')
  if (!idleMedia) {
    return <div className="alert alert-danger">Error: Idle MediaConfig not found!</div>
  }
  const waitMedia = additionalMedia.find((p) => p.mediaType === 'WAIT_DOUBLE_BOTTOM')
  if (!waitMedia) {
    return <div className="alert alert-danger">Error: Wait MediaConfig not found!</div>
  }
  return (
    <div className="my-sm">
      <div className={'px-3' + (activeTab === 'border' ? '' : ' hidden')} style={{ maxWidth: '300px' }}>
        <MediaPreview media={media} />
      </div>

      <div className={'px-3' + (activeTab === 'idle' ? '' : ' hidden')}>
        <DoubleTouchRvmPreview topMedia={topMedia} bottomMedia={idleMedia} borderMedia={media} />
      </div>

      <div className={'px-3' + (activeTab === 'wait' ? '' : ' hidden')}>
        <DoubleTouchRvmPreview topMedia={topMedia} bottomMedia={waitMedia} borderMedia={media} />
      </div>

      <div className="flex justify-around max-screen-xs">
        <TabLink name="border" title="Border image" activeTab={activeTab} setActiveTabFn={setActiveTab} />
        <TabLink name="idle" title="Idle screen" activeTab={activeTab} setActiveTabFn={setActiveTab} />
        <TabLink name="wait" title="Wait screen" activeTab={activeTab} setActiveTabFn={setActiveTab} />
      </div>
    </div>
  )
}
