type QuestionCircleIconProps = {
  color?: string
  size?: string
}

export const QuestionCircleIcon = (props: QuestionCircleIconProps) => (
  <svg
    fill={props.color || 'none'}
    height={props.size || '24'}
    viewBox="0 0 24 24"
    width={props.size || '24'}
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth="1.5px"
    stroke="#000000"
  >
    <path className="a" d="M9,9a3,3,0,1,1,4,2.829,1.5,1.5,0,0,0-1,1.415V14.25" />
    <path className="a" d="M12,17.25a.375.375,0,1,0,.375.375A.375.375,0,0,0,12,17.25h0" />
    <circle className="b" cx="12" cy="12" r="11.25" />
  </svg>
)
