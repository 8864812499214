import { ReactNode } from 'react'

export function InterruptableLink(props: {
  href?: string
  className?: string
  hasUnsavedChanges: boolean
  onClick?: any
  children: ReactNode
  onNavigate?: any
}) {
  return (
    <a
      href={props.href}
      className={props.className}
      onClick={(e) => {
        const confirmed =
          !props.hasUnsavedChanges ||
          window.confirm('You have unsaved changes that will be lost if you navigate away from this page!')
        if (!confirmed) {
          e.preventDefault()
          e.stopPropagation()
        } else {
          props.onNavigate && props.onNavigate()
          props.onClick && props.onClick(e)
        }
      }}
    >
      {props.children}
    </a>
  )
}
