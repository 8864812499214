import { FunctionComponent, useEffect, useState } from 'react'
import { AppMenuMountDiv } from './AppMenuMountDiv'
import { Logo, LogoutIcon } from '../icons'
import { failure, loading, notAsked, RD, SRD, success } from 'srd'
import { fetchLicenseAccount } from '../lib'
import { LicenseAccountRef } from '../dataTypes'

type HeaderProps = {
  doLogout: () => void
  userToken: string
  originatingEnvironment: string
  licenseAccountId: string
}

export const Header: FunctionComponent<HeaderProps> = ({
  doLogout,
  userToken,
  originatingEnvironment,
  licenseAccountId,
}) => {
  const [remoteLicenseAccount, setRemoteLicenseAccount] = useState(notAsked() as RD<string, LicenseAccountRef>)
  const remoteLicenseAccountName: RD<string, string> = SRD.map((la) => la.displayName, remoteLicenseAccount)
  useEffect(() => {
    if (SRD.isNotAsked(remoteLicenseAccount) && originatingEnvironment && licenseAccountId) {
      setRemoteLicenseAccount(loading())
      fetchLicenseAccount(originatingEnvironment, parseInt(licenseAccountId, 10))
        .then((licenseAccount: LicenseAccountRef) => {
          if (licenseAccount) {
            setRemoteLicenseAccount(success(licenseAccount))
          } else {
            setRemoteLicenseAccount(failure(`Unknown license account (${licenseAccountId})`))
          }
        })
        .catch((e) => {
          setRemoteLicenseAccount(failure(e.message))
        })
    }
  }, [originatingEnvironment, licenseAccountId, remoteLicenseAccount])

  const backUrl =
    originatingEnvironment && licenseAccountId
      ? `https://${originatingEnvironment}.tomraconnect.com/page/promotion/dashboard?licenseAccountId=${licenseAccountId}`
      : ''
  return (
    <div className="navbar">
      <div className="flex items-center gap-md">
        <Logo />
        <a href={backUrl}>Promotions</a>
      </div>

      <div className="flex gap-md">
        <div>
          <div className="text-sm">License account:</div>
          <div>{SRD.withDefault(licenseAccountId, remoteLicenseAccountName)}</div>
        </div>

        <AppMenuMountDiv userToken={userToken} />

        <button onClick={doLogout}>
          <LogoutIcon color="white" />
        </button>
      </div>
    </div>
  )
}
