import { isImageFile, isVideoFile } from '../../lib'
import { MediaConfig } from '../../dataTypes'

type Props = {
  media: MediaConfig
  borderMediaConfig: MediaConfig
}
export const UnoRvmPreview = ({ media, borderMediaConfig }: Props) => {
  const mediaUrl = media.customMedia?.filenamePath || media.defaultFilenamePath
  const contentType = media.customMedia?.contentType || media.defaultMediaContentType
  const borderThumbnailUrl = borderMediaConfig.customMedia?.thumbnailPath || borderMediaConfig.defaultFilenamePath

  return (
    <div
      style={{
        backgroundImage: 'url(' + process.env.PUBLIC_URL + '/images/uno-bg.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: '480px',
        height: '300px',
        position: 'relative',
      }}
    >
      <div
        style={{
          backgroundImage: 'url(' + borderThumbnailUrl + ')',
          backgroundRepeat: 'repeat',
          position: 'relative',
          width: '190px',
          height: '140px',
          top: '69px',
          left: '71px',
          borderRadius: '3px',
        }}
      >
        {isImageFile(contentType) && (
          <img
            src={mediaUrl}
            alt={'Uno preview'}
            style={{
              position: 'absolute',
              top: '0px',
              bottom: '0px',
              left: '15px',
              maxWidth: '158px',
              width: '100%',
              margin: 'auto',
            }}
          />
        )}
        {isVideoFile(contentType) && (
          <video
            style={{
              position: 'absolute',
              top: '0px',
              bottom: '0px',
              left: '15px',
              maxWidth: '158px',
              width: '100%',
              margin: 'auto',
            }}
            controls={true}
            autoPlay={true}
            muted={true}
            loop={true}
          >
            <source src={mediaUrl} type={contentType} />
            Your browser does not support video
          </video>
        )}
      </div>
    </div>
  )
}
