import { MediaType, ScreenType } from '../dataTypes'
import texts from '../lib/texts.json'

export function getScreenText(screenType: ScreenType): { name: string; extraInfo: string } {
  return (
    texts.screens[screenType] ?? {
      name: 'Unknown screen type ' + screenType,
      extraInfo: '',
    }
  )
}

export function getMediaText(mediaType: MediaType): { name: string; extraInfo: string } {
  return texts.media[mediaType] ?? { name: 'Unknown media type ' + mediaType, extraInfo: '' }
}
