import { MediaConfig } from '../../dataTypes'
import { isImageFile, isVideoFile } from '../../lib'

type Props = {
  topMedia: MediaConfig
  bottomMedia: MediaConfig
  borderMedia: MediaConfig
}
export const DoubleTouchRvmPreview = ({ topMedia, bottomMedia, borderMedia }: Props) => {
  const topMediaUrl = topMedia.customMedia?.filenamePath || topMedia.defaultFilenamePath
  const topMediaContentType = topMedia.customMedia?.contentType || topMedia.defaultMediaContentType
  const bottomMediaUrl = bottomMedia.customMedia?.filenamePath || bottomMedia.defaultFilenamePath
  const bottomMediaContentType = bottomMedia.customMedia?.contentType || bottomMedia.defaultMediaContentType
  const borderImageUrl = borderMedia.customMedia?.filenamePath || borderMedia.defaultFilenamePath
  return (
    <div
      style={{
        backgroundImage: 'url(' + process.env.PUBLIC_URL + '/images/doubletouch-bg.png)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: '500px',
        height: '332px',
        position: 'relative',
      }}
    >
      <div
        style={{
          backgroundImage: `url(${borderImageUrl})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          position: 'absolute',
          overflow: 'hidden',
          top: '37px',
          left: '66px',
          width: '156px',
          height: '272px',
        }}
      >
        <div style={{ margin: 'auto', height: '146px', width: '156px', position: 'relative' }}>
          {isImageFile(topMediaContentType) && (
            <img
              src={topMediaUrl}
              style={{ width: '100%', position: 'absolute', top: '0px', bottom: '0px', margin: 'auto' }}
              alt={'Double touch top preview'}
            />
          )}
          {isVideoFile(topMediaContentType) && (
            <video
              style={{ width: '100%', position: 'absolute', top: '0px', bottom: '0px', margin: 'auto' }}
              controls={true}
              autoPlay={true}
              muted={true}
              loop={true}
            >
              <source src={bottomMediaUrl} type={bottomMediaContentType} />
              Your browser does not support video
            </video>
          )}
        </div>
        <div
          style={{
            backgroundImage: 'url(' + process.env.PUBLIC_URL + '/images/take_idle_touch-bg.gif)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            marginTop: '10px',
            height: '120px',
            width: '100%',
            position: 'relative',
          }}
        >
          <div style={{ height: '92px', width: '142px', left: '10px', top: '10px', position: 'absolute' }}>
            {isImageFile(bottomMediaContentType) && (
              <img src={bottomMediaUrl} style={{ width: '100%' }} alt={'Double touch bottom preview'} />
            )}

            {isVideoFile(bottomMediaContentType) && (
              <video style={{ width: '100%' }} controls={true} autoPlay={true} muted={true} loop={true}>
                <source src={bottomMediaUrl} type={bottomMediaContentType} />
                Your browser does not support video
              </video>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
