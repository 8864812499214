import { Dispatch, SetStateAction, useState } from 'react'
import { failure, RD, success } from 'srd'

import { FullTemplate } from '../dataTypes'
import { ScreenInfo } from '../components'
import { ArrowRightIcon, EditIcon, HomeIcon, InfoCircleIcon } from '../icons'
import { editTemplate } from '../lib'

type Props = {
  template: FullTemplate
  setTemplate: Dispatch<SetStateAction<RD<string, FullTemplate>>>
}

export const EditPage = ({ template, setTemplate }: Props) => {
  const [isEditMode, setEditMode] = useState(false)
  const {
    id,
    name,
    description,
    version,
    screens,
    originatingEnvironment,
    licenseAccountReference,
    deactivatedAt,
    finalizedAt,
  } = template
  const [newName, setNewName] = useState(name)
  const [newDescription, setNewDescription] = useState(description)
  const isLockedForModifications = deactivatedAt || finalizedAt

  const scheduleUrl = `https://${originatingEnvironment}.tomraconnect.com/page/promotion/schedule/time?externalRgId=${id}&licenseAccountId=${licenseAccountReference}`
  const templatesUrl = `https://${originatingEnvironment}.tomraconnect.com/page/promotion/media?licenseAccountId=${licenseAccountReference}`
  return (
    <div>
      <div className="card py-md px-lg flex items-center space-x-md">
        <a href={templatesUrl} aria-label="Home">
          <HomeIcon size="1.5rem" />
        </a>
        <ArrowRightIcon size="1.5rem" />
        {name}
        <ArrowRightIcon size="1.5rem" />
        {isLockedForModifications ? 'View template ' : 'Edit template'}
      </div>
      <div className={isEditMode ? 'hidden' : ''}>
        <h1 className="text-lg">{name}</h1>
        <span className={!description ? 'text-grey italic' : ''}>{description || 'no description added'}</span>
        <button
          className={isLockedForModifications ? 'hidden' : 'btn btn-icon inline-block ml-sm align-middle min-h-0'}
          style={{ minHeight: 0 }}
          onClick={(e) => {
            setEditMode(true)
            e.preventDefault()
          }}
          title="Edit details"
        >
          <EditIcon />
        </button>
      </div>
      <form
        className={isEditMode ? 'my-md' : 'hidden'}
        onSubmit={(e) => {
          e.preventDefault()
          const newTemplate = { ...template, newName, description: newDescription, version: version }
          editTemplate(id, newTemplate)
            .then((updatedTemplateDetails) => {
              setTemplate(success({ ...newTemplate, version: updatedTemplateDetails.version }))
            })
            .catch((e) => {
              console.log(e.status)
              if (e.status === 409) {
                setTemplate(failure('Template has been modified since last reload, please refresh and try again'))
              } else {
                setTemplate(failure(e.message))
              }
            })
          setEditMode(false)
        }}
        onReset={() => {
          setNewName(name)
          setNewDescription(description)
          setEditMode(false)
        }}
      >
        <input
          type="text"
          id="name-input"
          value={newName}
          maxLength={26}
          onChange={(e) => setNewName(e.target.value)}
        />
        <textarea
          id="description-input"
          className="my-md"
          value={newDescription ?? ''}
          onChange={(e) => setNewDescription(e.target.value)}
        />
        <div className="mb-md text-right">
          <input
            className="btn btn-primary-dark inline-block mr-md"
            disabled={name === newName && description === newDescription}
            type="submit"
            value="Save"
          />
          <input className="btn inline-block" type="reset" value="Cancel" />
        </div>
      </form>
      <div className="my-md">
        <div className="py-md inline-flex">
          <InfoCircleIcon />
          <span className="ml-xs">
            {' '}
            Customize media for the screens you want to use in this campaign. The screens you do not edit will not be
            affected.
          </span>
        </div>
        {screens.map((screen) => (
          <div key={'' + id + screen.screenType} className="card mt-lg p-md">
            <ScreenInfo templateId={id} screen={screen} originatingEnvironment={originatingEnvironment} />
          </div>
        ))}

        <div className="flex justify-end my-lg">
          <a className="btn" href={templatesUrl}>
            Back to templates
          </a>
          <a className="btn btn-primary-dark ml-md" href={scheduleUrl}>
            Schedule campaign
          </a>
        </div>
      </div>
    </div>
  )
}
