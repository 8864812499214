import { Dispatch, SetStateAction, useState } from 'react'
import { CopyTemplateChoice } from './CopyTemplateChoice'

type Props = {
  templateName: string
  templateDescription: string
  enableCopyTemplateChoice: boolean
  createTemplateErrorMessage: string
  save: (name: string, description: string, copyFromTemplateId: string | undefined) => void
  cancel: () => void
}

export const TemplateDetailsForm = ({
  templateName,
  templateDescription,
  enableCopyTemplateChoice,
  createTemplateErrorMessage,

  save,
  cancel,
}: Props) => {
  const [name, setName] = useState(templateName)
  const [description, setDescription] = useState(templateDescription)
  const [chosenExistingId, setChosenExistingId]: [string | undefined, Dispatch<SetStateAction<string | undefined>>] =
    useState()
  return (
    <form
      onSubmit={(e) => {
        save(name, description, chosenExistingId)
        e.preventDefault()
      }}
      onReset={(e) => {
        setName(templateName)
        setDescription(templateDescription)
        cancel()
        e.preventDefault()
      }}
    >
      <div className="mb-md">
        <label className="label" htmlFor="name-input">
          Name (required, max 26 characters):
        </label>
        <input type="text" id="name-input" value={name} maxLength={26} onChange={(e) => setName(e.target.value)} />
        {createTemplateErrorMessage && (
          <div className="alert alert-danger flex mt-xs">{createTemplateErrorMessage}</div>
        )}
      </div>
      {enableCopyTemplateChoice && (
        <CopyTemplateChoice setChosenExistingId={setChosenExistingId} chosenExistingId={chosenExistingId} />
      )}
      <div className="mb-md">
        <label className="label" htmlFor="description-input">
          Description (optional):
        </label>
        <textarea id="description-input" value={description} onChange={(e) => setDescription(e.target.value)} />
      </div>
      <div className="mb-md">
        <input
          className="btn btn-primary-dark inline-block mr-md"
          disabled={name === templateName && description === templateDescription}
          type="submit"
          value="Save"
        />
        <input className="btn inline-block" type="reset" value="Reset" />
      </div>
    </form>
  )
}
