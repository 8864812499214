import { MediaConfig } from '../dataTypes'

type MediaUploadInfoProps = { media: MediaConfig }
export const MediaUploadInfo = ({ media }: MediaUploadInfoProps) => {
  return (
    <div>
      {media.description && <div className="w-full mb-md">{media.description}</div>}
      <div className="flex">
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/2 xl:w-2/6">
          <div className="font-bold">Media Type</div>
        </div>
        <div className="w-full sm:w-1/2 md:w-2/3 lg:w-1/2 xl:w-4/6">
          <div>{media.mediaType}</div>
        </div>
      </div>
      <div className="flex">
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/2 xl:w-2/6">
          <div className="font-bold">Media Resolution</div>
        </div>
        <div className="w-full sm:w-1/2 md:w-2/3 lg:w-1/2 xl:w-4/6">
          <div>{'' + media.resolutionWidth + ' x ' + media.resolutionHeight}</div>
        </div>
      </div>
      <div className="flex">
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/2 xl:w-2/6">
          <div className="font-bold">Supports video</div>
        </div>
        <div className="w-full sm:w-1/2 md:w-2/3 lg:w-1/2 xl:w-4/6">
          <div>{media.videoSupport ? 'true' : 'false'}</div>
        </div>
      </div>
      <div className="flex">
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/2 xl:w-2/6">
          <div className="font-bold">{media.customMedia?.contentType ? 'Uploaded' : 'Default'} Content Type</div>
        </div>
        <div className="w-full sm:w-1/2 md:w-2/3 lg:w-1/2 xl:w-4/6">
          <div>{media.customMedia?.contentType || media.defaultMediaContentType}</div>
        </div>
      </div>
      <div className="flex mb-md">
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/2 xl:w-2/6">
          <div className="font-bold">Current Converted File</div>
        </div>
        <div className="w-full sm:w-1/2 md:w-2/3 lg:w-1/2 xl:w-4/6">
          <div>
            {(media.customMedia?.filenamePath && (
              <a
                href={media.customMedia.filenamePath}
                target={'_blank'}
                rel="noreferrer"
                download
                className="text-blue"
              >
                View file
              </a>
            )) || <span>default</span>}
          </div>
        </div>
      </div>
    </div>
  )
}
