import { MediaConfig, ScreenType } from '../dataTypes'
import { SingleBorderPreview } from './SingleBorderPreview'
import { DoubleBorderPreview } from './DoubleBorderPreview'
import { ExtraLargeTouchAccountRvmPreview } from './rvm-previews/ExtraLargeTouchAccountRvmPreview'
import { ColourTouchRvmPreview } from './rvm-previews/ColourTouchRvmPreview'
import { UnoRvmPreview } from './rvm-previews/UnoRvmPreview'
import { DoubleTouchRvmPreview } from './rvm-previews/DoubleTouchRvmPreview'
import { SmallColourRvmPreview } from './rvm-previews/SmallColourRvmPreview'

type Props = {
  screenType: ScreenType
  media: MediaConfig
  additionalMedia: MediaConfig[]
}

export const Preview = ({ screenType, media, additionalMedia }: Props) => {
  // 'SMALL_COLOUR' | 'COLOUR_TOUCH' | 'UNO' | 'EXTRA_LARGE_TOUCH' | 'DOUBLE_TOUCH'
  return (
    <div className="my-sm centerFlex">
      {screenType === 'UNO' && media.mediaType === 'BORDER' && (
        <SingleBorderPreview mediaConfig={media} additionalMedia={additionalMedia} />
      )}
      {screenType === 'UNO' && media.mediaType !== 'BORDER' && (
        <UnoRvmPreview
          media={media}
          borderMediaConfig={additionalMedia.find((mediaConfig) => mediaConfig.mediaType === 'BORDER')!!}
        />
      )}
      {screenType === 'DOUBLE_TOUCH' && media.mediaType === 'DOUBLE_TOP' && (
        <DoubleTouchRvmPreview
          topMedia={media}
          bottomMedia={additionalMedia.find((p) => p.mediaType === 'IDLE_DOUBLE_BOTTOM')!!}
          borderMedia={additionalMedia.find((p) => p.mediaType === 'DOUBLE_SCREEN_BORDER')!!}
        />
      )}
      {screenType === 'DOUBLE_TOUCH' && media.mediaType === 'DOUBLE_SCREEN_BORDER' && (
        <DoubleBorderPreview media={media} additionalMedia={additionalMedia} />
      )}
      {screenType === 'DOUBLE_TOUCH' &&
        (media.mediaType === 'IDLE_DOUBLE_BOTTOM' || media.mediaType === 'WAIT_DOUBLE_BOTTOM') && (
          <DoubleTouchRvmPreview
            topMedia={additionalMedia.find((p) => p.mediaType === 'DOUBLE_TOP')!!}
            bottomMedia={media}
            borderMedia={additionalMedia.find((p) => p.mediaType === 'DOUBLE_SCREEN_BORDER')!!}
          />
        )}
      {screenType === 'SMALL_COLOUR' && <SmallColourRvmPreview media={media} />}
      {screenType === 'COLOUR_TOUCH' && <ColourTouchRvmPreview media={media} />}
      {screenType === 'EXTRA_LARGE_TOUCH' && <ExtraLargeTouchAccountRvmPreview media={media} />}
    </div>
  )
}
