import { Dispatch, SetStateAction, useState } from 'react'
import { failure, RD, success } from 'srd'
import { useLocation } from 'wouter'

import { FullTemplate, TemplateDetails } from '../dataTypes'
import { copyTemplate, createTemplate, ORIG_ENV_QUERY_NAME } from '../lib'

import { TemplateDetailsForm } from '../components'
import { ForTestingComponent } from '../components/ForTestingComponent'

type Props = {
  setTemplate: Dispatch<SetStateAction<RD<string, FullTemplate>>>
  originatingEnvironment: string
  licenseAccountId: string
}

export const StartPage = ({ setTemplate, originatingEnvironment, licenseAccountId }: Props) => {
  const setLocation = useLocation()[1]
  const [createTemplateErrorMessage, setCreateTemplateErrorMessage] = useState('')

  return (
    <div>
      <div className="card p-md my-md">
        <h2 className="text-lg pb-2">Create template</h2>
        <TemplateDetailsForm
          templateName={''}
          templateDescription={''}
          enableCopyTemplateChoice={true}
          createTemplateErrorMessage={createTemplateErrorMessage}
          save={(name, description, chosenExistingId) => {
            if (chosenExistingId) {
              console.log(
                `Creating new template from chosenExistingId: ${chosenExistingId} name: ${name} description: ${description}`
              )
              copyTemplate(chosenExistingId, name, originatingEnvironment)
                .then((data) => {
                  setTemplate(success(data))
                  setLocation(`/${data.id}?${ORIG_ENV_QUERY_NAME}=${originatingEnvironment}`)
                })
                .catch((e) => {
                  if (e.status === 400) {
                    setCreateTemplateErrorMessage(`${e.body.detail} (${licenseAccountId})`)
                  } else {
                    setTemplate(failure(e.message))
                  }
                })
            } else {
              const newTemplate: TemplateDetails = {
                originatingEnvironment: originatingEnvironment,
                licenseAccountReference: licenseAccountId,
                name,
                description,
                version: -1,
              }
              console.log('Creating template ' + JSON.stringify(newTemplate))
              createTemplate(newTemplate)
                .then((data) => {
                  setTemplate(success(data))
                  setLocation(`/${data.id}?${ORIG_ENV_QUERY_NAME}=${originatingEnvironment}`)
                })
                .catch((e) => {
                  if (e.status === 400) {
                    setCreateTemplateErrorMessage(`${e.body.detail} (${licenseAccountId})`)
                  } else {
                    setTemplate(failure(e.message))
                  }
                })
            }
          }}
          cancel={() => {
            setCreateTemplateErrorMessage('')
          }}
        />
      </div>
      {originatingEnvironment.endsWith('test') && <ForTestingComponent />}
    </div>
  )
}
