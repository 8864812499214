import { TemplateRef } from '../dataTypes'
import { Link } from 'wouter'
import { fetchTemplates, getLicenseAccountParam, getOriginatingEnvironmentParam, ORIG_ENV_QUERY_NAME } from '../lib'
import { useEffect, useState } from 'react'
import { failure, loading, notAsked, RD, SRD, success } from 'srd'

export const ForTestingComponent = () => {
  const originatingEnvironmentParam = getOriginatingEnvironmentParam()
  const [existingTemplates, setExistingTemplates] = useState(notAsked() as RD<string, TemplateRef[]>)
  const [showTemplates, setShowTemplates] = useState(false)

  useEffect(() => {
    let isCancelled = false
    setExistingTemplates(loading())
    fetchTemplates(originatingEnvironmentParam, getLicenseAccountParam())
      .then((data) => {
        if (!isCancelled) {
          setExistingTemplates(success(data))
        }
      })
      .catch((e) => {
        if (!isCancelled) {
          setExistingTemplates(failure(e.message))
        }
        console.log('Error setting existing templates', e)
      })
    return () => {
      isCancelled = true
    }
  }, [originatingEnvironmentParam])

  return SRD.match(
    {
      failure(e: string) {
        return <div>{e}</div>
      },
      loading() {
        return <div>loading existing templates...</div>
      },
      notAsked() {
        return <div>loading existing templates...</div>
      },
      success(existingTemplates: TemplateRef[]) {
        return (
          <div className="text-sm text-grey">
            <button onClick={() => setShowTemplates(!showTemplates)}>
              Click to show/hide templates (only for test)
            </button>
            <div hidden={!showTemplates}>
              {existingTemplates.length > 0 && (
                <ExistingTemplates
                  existingTemplates={existingTemplates}
                  originatingEnvironmentParam={originatingEnvironmentParam}
                />
              )}
              {existingTemplates.length === 0 && <div>No existing templates</div>}
            </div>
          </div>
        )
      },
    },
    existingTemplates
  )
}

type ExistingTemplatesProps = {
  existingTemplates: TemplateRef[]
  originatingEnvironmentParam: string
}

const ExistingTemplates = ({ existingTemplates, originatingEnvironmentParam }: ExistingTemplatesProps) => {
  const links = existingTemplates.map((template) => (
    <li key={template.id}>
      <Link
        className="underline text-blue"
        to={`/${template.id}?${ORIG_ENV_QUERY_NAME}=${originatingEnvironmentParam}`}
      >
        {template.name}
      </Link>
    </li>
  ))
  return <ul className="">{links}</ul>
}
