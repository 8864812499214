import { isImageFile, isVideoFile } from '../../lib'
import { MediaConfig } from '../../dataTypes'

type Props = {
  media: MediaConfig
}
export const ExtraLargeTouchAccountRvmPreview = ({ media }: Props) => {
  const mediaUrl = media.customMedia?.filenamePath || media.defaultFilenamePath
  const contentTypeWithFallBack = media.customMedia?.contentType || media.defaultMediaContentType
  return (
    <div
      style={{
        backgroundImage: 'url(' + process.env.PUBLIC_URL + '/images/extralargetouch_bg.png)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: '545px',
        height: '402px',
        position: 'relative',
        marginBottom: '20px',
      }}
    >
      <div
        style={{
          width: '173px',
          height: '308px',
          position: 'absolute',
          overflow: 'hidden',
          top: '25px',
          left: '314px',
          backgroundColor: '#000',
        }}
      >
        {isImageFile(contentTypeWithFallBack) && (
          <img src={mediaUrl} alt={'Extra large touch preview'} style={{ marginTop: '165px' }} />
        )}
        {isVideoFile(contentTypeWithFallBack) && (
          <video controls={true} autoPlay={true} muted={true} loop={true}>
            <source src={mediaUrl} type={contentTypeWithFallBack} />
            Your browser does not support video
          </video>
        )}
      </div>
    </div>
  )
}
