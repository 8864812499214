import { useEffect, useState } from 'react'
import { failure, loading, notAsked, RD, SRD, success } from 'srd'
import { LicenseAccountRef } from '../dataTypes'
import { fetchLicenseAccounts } from '../lib/service'

type Props = {
  originatingEnvironment: string
}

export const LicenseAccountChooser = ({ originatingEnvironment }: Props) => {
  const [remoteLicenseAccounts, setRemoteLicenseAccounts] = useState(notAsked() as RD<string, LicenseAccountRef[]>)
  useEffect(() => {
    if (SRD.isNotAsked(remoteLicenseAccounts)) {
      setRemoteLicenseAccounts(loading())
      fetchLicenseAccounts(originatingEnvironment)
        .then((data) => {
          setRemoteLicenseAccounts(success(data))
        })
        .catch((e) => {
          setRemoteLicenseAccounts(failure(e.message))
          console.log('Error setting existing templates', e)
        })
    }
  }, [originatingEnvironment, remoteLicenseAccounts])

  return SRD.match(
    {
      notAsked() {
        return <div>loading available license accounts...</div>
      },
      loading() {
        return <div>loading available license accounts...</div>
      },
      failure(e: string) {
        return <div>{e}</div>
      },
      success(licenseAccounts: LicenseAccountRef[]) {
        return (
          <div>
            <select
              className="select p-sm"
              onChange={(e) =>
                (window.location.href = `/?origEnv=${originatingEnvironment}&licenseAccount=${e.target.value}`)
              }
              defaultValue={0}
            >
              <option value={0} key={0} disabled={true}>
                Choose a license account
              </option>
              {licenseAccounts.map((la) => {
                return (
                  <option value={la.licenseAccountId} key={la.licenseAccountId}>
                    {la.displayName}
                  </option>
                )
              })}
            </select>
          </div>
        )
      },
    },
    remoteLicenseAccounts
  )
}
