import { Dispatch, SetStateAction, useState } from 'react'

type Props = {
  newFile: File | null
  setNewFile: Dispatch<SetStateAction<File | null>>
}

export const FileDrop = ({ newFile, setNewFile }: Props) => {
  const [isDragOver, setDragOver] = useState(false)
  const [dropError, setDropError] = useState('')
  return (
    <div>
      <div
        className="card px-md py-xl mb-sm bg-sand border-dashed border-4"
        style={{ borderColor: '#CCCDC5' }}
        onDragOver={(e) => e.preventDefault()}
        onDragEnter={(e) => {
          if (!isDragOver) {
            setDragOver(true)
          }
          setDropError('')
          e.preventDefault()
        }}
        onDragLeave={(e) => {
          if (isDragOver) {
            setDragOver(false)
          }
          e.preventDefault()
        }}
        onDrop={(ev) => {
          console.log('dropping')
          if (ev.dataTransfer.items) {
            if (ev.dataTransfer.items.length !== 1) {
              setDropError('Only one file allowed')
            } else {
              const item = ev.dataTransfer.items[0]
              // If dropped items aren't files, reject them
              if (item.kind === 'file') {
                const file = item.getAsFile()
                console.log('... file.name = ' + file?.name)
                setNewFile(file)
              }
            }
          }
          setDragOver(false)
          ev.preventDefault()
        }}
      >
        {newFile ? newFile.name : 'Or drop file to upload'}
      </div>
      {isDragOver && <div className="alert alert-success">Drop to upload</div>}
      {dropError && <div className="alert alert-danger">{dropError}</div>}
    </div>
  )
}
