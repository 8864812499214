import { isImageFile, isVideoFile } from '../../lib'
import { MediaConfig } from '../../dataTypes'

type Props = {
  media: MediaConfig
}

export const ColourTouchRvmPreview = ({ media }: Props) => {
  const mediaUrl = media.customMedia?.filenamePath || media.defaultFilenamePath
  const contentType = media.customMedia?.contentType || media.defaultMediaContentType
  return (
    <div
      style={{
        backgroundImage: 'url(' + process.env.PUBLIC_URL + '/images/colourtouch-bg.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: '402px',
        height: '272px',
        position: 'relative',
      }}
    >
      <div
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/take_idle_touch-bg.gif`,
          backgroundSize: 'cover',
          position: 'absolute',
          top: '76px',
          left: '241px',
          width: '150px',
          height: '107px',
        }}
      >
        {isImageFile(contentType) && (
          <img
            src={mediaUrl}
            alt={'Colour touch preview'}
            style={{
              position: 'absolute',
              top: '12px',
              left: '10px',
              maxWidth: '128px',
              width: '100%',
              margin: 'auto',
            }}
          />
        )}
        {isVideoFile(contentType) && (
          <video
            style={{
              position: 'absolute',
              top: '12px',
              left: '10px',
              maxWidth: '128px',
              width: '100%',
              margin: 'auto',
            }}
            controls={true}
            autoPlay={true}
            muted={true}
            loop={true}
          >
            <source src={mediaUrl} type={contentType} />
            Your browser does not support video
          </video>
        )}
      </div>
    </div>
  )
}
